import { acceptHMRUpdate, defineStore } from 'pinia'

import { dashboarCommonInitialState as dashboardCommonState } from '~/stores/dashboardCommon'

import { configToQs } from '~/helpers/configToQs'
import { resetStoreToInitialState } from '~/helpers/resetStoreToInitialState'

import type { PaginatedApiResponse } from '~/types/PaginatedApiResponse'
import type Submission from '~/types/submission'
import type SubmissionStatus from '~/types/submissionStatus'

const state = () => ({
  ...dashboardCommonState(),
  status_priority: ['Fn', 'Wa', 'Wc', 'Se', 'Ex'] as SubmissionStatus[],
  filters: [['Fn', 'Wa', 'Wc'], ['Se'], ['Ex']] as const,
  selected_filter_index: 0,
  selected_submission_id: 0,
})

export type BandDashboardState = ReturnType<typeof state>

type ApiResponse = PaginatedApiResponse<Submission>

interface FetchConfig {
  offset: number
  limit: number
  status: SubmissionStatus
  stopAtStatus: SubmissionStatus[]
  noNext: boolean
}

export const useBandDashboardStore = defineStore('bandDashboard', {
  state: (): BandDashboardState => ({ ...state() }),
  actions: {
    CHANGE_META_FILTER(index: number) {
      this.selected_filter_index = index
    },
    SELECT_SUBMISSION(id: number) {
      this.selected_submission_id = id
    },
    RESET() {
      resetStoreToInitialState.bind(this)(state())
    },
    // from dashboard common
    ADD_FETCHED_STATUS(status: SubmissionStatus) {
      this.fetched_status.push(status)
    },
    MERGE_SUBMISSIONS_FROM_BATCH({
      batch,
      status,
    }: {
      batch: Record<number, Submission>
      status: SubmissionStatus
    }) {
      const mergeArray: number[] = []

      Object.entries(batch).forEach(([, value]) => {
        mergeArray.push(value.id)
      })
      this.submissions_by_status[status] = [
        ...new Set([...this.submissions_by_status[status], ...mergeArray]),
      ]
      this.submissions = { ...this.submissions, ...batch }
      this.submission_count += mergeArray.length
    },
    REMOVE_SUBMISSION({
      submission_id,
      last_status,
    }: {
      submission_id: number | undefined
      last_status: SubmissionStatus | undefined
    }) {
      if (!submission_id) return

      const lastStatus = last_status || 'Se'
      const lastStatusIndex =
        this.submissions_by_status[lastStatus].indexOf(submission_id)

      delete this.submissions[submission_id]
      this.submission_count--
      this.submissions_by_status[lastStatus].splice(lastStatusIndex, 1)
    },
    ADD_SUBMISSION(submission: Submission) {
      // This happens when submission is fresh out of API response :(
      if (typeof submission.status_history === 'string')
        submission.status_history = JSON.parse(submission.status_history)

      if (submission.pk) submission.id = submission.pk

      if (
        !this.submissions_by_status[submission.status].includes(submission.id)
      ) {
        this.submissions[submission.id] = submission
        this.submissions_by_status[submission.status].push(submission.id)
        this.submission_count++
      }
    },
    SET_LOADING(value: boolean) {
      if (typeof value === 'boolean') this.loading = value
    },
    UPDATE_SUBMISSION({
      submission,
      last_status,
    }: {
      submission: Submission
      last_status?: SubmissionStatus
    }) {
      // This happens when submission is fresh out of API response :(
      if (typeof submission.status_history === 'string')
        submission.status_history = JSON.parse(submission.status_history)

      this.REMOVE_SUBMISSION({
        submission_id: submission.id || submission.pk,
        last_status:
          last_status ||
          submission.status_history[submission.status_history.length - 1] ||
          'Se',
      })
      this.ADD_SUBMISSION(submission)
    },
    async FETCH_SUBMISSIONS_BY_STATUS({
      offset = 0,
      limit = 512,
      status = 'Se',
      stopAtStatus = [],
      noNext = false,
    }: FetchConfig) {
      if (typeof stopAtStatus === 'string') stopAtStatus = [stopAtStatus]

      try {
        const time = new Date().getTime()
        const url = `/submission/dashboard/?${configToQs({
          limit,
          offset,
          status,
          time,
        })}`
        const { results, next }: ApiResponse = await $coreFetch.$get(url)

        const batch: Record<number, Submission> = {}

        for (let i = results.length - 1; i >= 0; i--) {
          const submission = results[i]

          if (!this.submissions[submission.id]) {
            if (
              submission.status_history &&
              submission.status_history.length &&
              typeof submission.status_history === 'string'
            )
              submission.status_history = JSON.parse(submission.status_history)

            batch[submission.id] = submission
          }
        }
        this.MERGE_SUBMISSIONS_FROM_BATCH({ batch, status })
        offset += limit
        if (process.client && !noNext) {
          const currentStatusIndex = this.status_priority.indexOf(status)

          if (next) {
            this.FETCH_SUBMISSIONS_BY_STATUS({
              limit,
              offset,
              status,
              stopAtStatus,
              noNext,
            })
          } else if (currentStatusIndex < this.status_priority.length - 1) {
            if (
              !stopAtStatus.includes(
                this.status_priority[currentStatusIndex + 1],
              )
            ) {
              status = this.status_priority[currentStatusIndex + 1]
              offset = 0
              this.FETCH_SUBMISSIONS_BY_STATUS({
                limit,
                offset,
                status,
                stopAtStatus,
                noNext,
              })
            }
          }
        }
      } catch (_) {
        // mute error
      }
    },
  },
  getters: {
    FILTER(state) {
      return state.filters[state.selected_filter_index]
    },
    // from dashboard common
    GET_SUBMISSION_FROM_ID(state) {
      return function (submissionId: number) {
        return state.submissions[submissionId]
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useBandDashboardStore, import.meta.hot),
  )
}
