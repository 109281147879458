<template>
  <div class="tw-grid tw-grid-cols-1">
    <NuxtLinkLocale to="/" class="hoverText" @click="$emit('close')">
      <VText v-once :cfg="textConfig">
        {{ $t('layout.navigation00') }}
      </VText>
    </NuxtLinkLocale>
    <NuxtLinkLocale
      to="/draft/"
      class="hoverText"
      @click="handleStartACampaignClicked"
    >
      <VText v-once :cfg="textConfig">
        {{ $t('layout.navigation11') }}
      </VText>
    </NuxtLinkLocale>
    <NuxtLinkLocale
      to="/band/dashboard/"
      class="hoverText"
      @click="$emit('close')"
    >
      <VText v-once :cfg="textConfig">
        {{ $t('layout.navigation04') }}
      </VText>
    </NuxtLinkLocale>
    <div v-if="IS_AGENCY" class="tw-grid tw-grid-cols-1 sm:tw-px-5">
      <div class="fakeBorder" />
      <BandSelect @close="$emit('close')" />
      <div class="fakeBorder" />
    </div>
    <VText
      v-if="IS_AGENCY"
      v-once
      :cfg="titleConfig"
      html-tag="div"
      class="tw-py-3 sm:tw-px-5"
    >
      {{ $t('band.RPMenu.account') }}
    </VText>
    <NuxtLinkLocale
      class="hoverText"
      to="/agency/favorites/"
      @click="$emit('close')"
    >
      <VText v-once :cfg="textConfig">
        {{ $t('layout.menu.menu00') }}
      </VText>
    </NuxtLinkLocale>
    <NuxtLinkLocale to="/band/shop/" class="hoverText" @click="$emit('close')">
      <VText
        v-once
        :cfg="textConfig"
        class="tw-flex tw-items-center tw-justify-start tw-gap-1"
      >
        <span class="feedbackText tw-text-black">{{
          $t('layout.navigation08', grooviz)
        }}</span>
        <img
          class="tw-h-4 tw-w-4 tw-overflow-hidden tw-object-contain tw-object-center"
          :src="getMediaUrl('grooviz_light_grey.svg')"
        />
      </VText>
    </NuxtLinkLocale>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import BandSelect from '~/components/nav/loggedIn/contextMenu/band/bandSelect.vue'
import VText from '~/components/ui/VText.vue'

import DashMenuMixin from '~/mixins/nav/dashMenu'
import SegmentCampaignEventsMixin from '~/mixins/Segment/Campaign'

import { useGetMediaUrl } from '~/composables/useGetMediaUrl'

import { useUserStore } from '~/stores/user'

import type { PropConfigType } from '~/components/ui/VText.vue'

export default defineComponent({
  components: {
    BandSelect,
    VText,
  },
  mixins: [DashMenuMixin, SegmentCampaignEventsMixin],
  emits: ['close'],
  setup() {
    const { getMediaUrl } = useGetMediaUrl()
    return { getMediaUrl }
  },
  data() {
    const textConfig: PropConfigType = 'sans/14/medium'
    const titleConfig: PropConfigType = 'sans/16/medium'

    return {
      expand: false,
      textConfig,
      titleConfig,
    }
  },
  computed: {
    chevron_feedback(): 'up' | 'down' {
      return this.expand ? 'up' : 'down'
    },
    ...mapState(useUserStore, ['grooviz', 'IS_AGENCY']),
    // ...mapState({
    //   grooviz: (state: any) => (state as RootState).user.grooviz,
    // }),
    // ...mapGetters({
    //   IS_AGENCY: 'user/IS_AGENCY',
    // }),
  },
  methods: {
    toggle(): void {
      this.expand = !this.expand
    },
    close(): void {
      this.$emit('close')
    },
    handleStartACampaignClicked(): void {
      this.trackCampaignInitiated('header')
      this.close()
    },
  },
})
</script>

<style lang="scss" scoped>
.fakeBorder {
  @apply tw-my-3 tw-h-px tw-w-full tw-bg-gray-200;
}
</style>
