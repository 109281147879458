<script setup lang="ts">
import { onHide, onShow, onUnreadCountChange } from '@intercom/messenger-js-sdk'
import { storeToRefs } from 'pinia'

import { useMiscIntercomStore } from '~/stores/miscIntercom'

const open = ref(false)
const notificationCount = ref(0)

const { higher: HIGHER } = storeToRefs(useMiscIntercomStore())

onMounted(() => {
  onUnreadCountChange((count: number) => {
    if (count) notificationCount.value = count
  })

  onHide(() => {
    open.value = false
  })

  onShow(() => {
    open.value = true
  })
})
</script>

<template>
  <div
    id="custom_intercom_button"
    class="tw-fixed tw-bottom-6 tw-left-6 tw-z-[10000] tw-h-15 tw-w-15 tw-cursor-pointer tw-transition-all"
    :class="{ 'tw-bottom-20 375:!tw-bottom-24': HIGHER }"
  >
    <div
      v-if="notificationCount"
      class="tw-absolute tw-mb-12 tw-ml-12 tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-rounded-full tw-bg-orange-500 tw-shadow-round"
    >
      <span class="tw-text-sm tw-font-semibold tw-text-white">{{
        notificationCount
      }}</span>
    </div>
    <div
      class="tw-flex tw-h-15 tw-w-15 tw-items-center tw-justify-center tw-rounded-full tw-bg-orange-500 tw-shadow-round"
    >
      <i class="far fa-comment-alt" :class="{ open }" />
      <i class="fas fa-times" :class="{ open }" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.fa-comment-alt {
  position: absolute;
  margin-top: 3px;
  font-size: var(--font-size-title-big);
  font-weight: 700;
  color: var(--color-white);
  transition: 0.2s var(--trans-standard);
  opacity: 1;
}
.fa-comment-alt.open {
  transition: 0.1s var(--trans-standard);
  opacity: 0;
}
.fa-times {
  position: absolute;
  margin-top: 2px;
  font-size: var(--font-size-title);
  color: var(--color-white);
  transition: 0.1s var(--trans-standard);
  transform: rotate(-45deg) translate(0px);
  opacity: 0;
}
.fa-times.open {
  transition: 0.2s var(--trans-standard);
  transform: rotate(0deg) translate(0px);
  opacity: 1;
}

@media screen and (max-width: 767px) {
  .fa-comment-alt {
    font-size: var(--font-size-title);
  }

  .fa-times {
    font-size: var(--font-size-title-small);
  }

  .notifValue {
    font-size: var(--font-size-eleven);
  }
}
</style>
