import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import { useBandDashboardStore } from '~/stores/bandDashboard'
import { useUserStore } from '~/stores/user'

export default defineComponent({
  emits: ['close'],
  setup() {
    const localePath = useLocalePath()

    return {
      localePath,
    }
  },
  data() {
    return {
      config: [
        {
          i18n: 'layout.navigation.dashMenu00',
          icon: { fas: true, 'fa-comment-dots': true },
          link: {
            path: '/band/dashboard/',
            query: { banddashtab: 'answered' },
          },
        },
        {
          i18n: 'layout.navigation.dashMenu01',
          icon: { fas: true, 'fa-clock': true },
          link: {
            path: '/band/dashboard/',
            query: { banddashtab: 'pending' },
          },
        },
        {
          i18n: 'layout.navigation.dashMenu02',
          icon: { fas: true, 'fa-calendar-times': true },
          link: {
            path: '/band/dashboard/',
            query: { banddashtab: 'expired' },
          },
        },
      ] as const,
    }
  },
  computed: {
    ...mapState(useUserStore, { SUBMISSION_ARRAY: 'submissions' }),
  },
  methods: {
    ...mapActions(useBandDashboardStore, ['CHANGE_META_FILTER']),
    handleLegacyDashboardClick(index: number): void {
      this.CHANGE_META_FILTER(index)
      if (!this.$route.path.includes('/band/dashboard/'))
        this.$router.push(this.localePath({ path: '/band/dashboard/' }))

      this.$emit('close')
    },
  },
})
