<template>
  <div>
    <NuxtLinkLocale
      to="/"
      data-test-id="bandTopNavigationHomeCTA"
      class="sharedNavigationText"
    >
      <VText :cfg="commonTextConfig">
        {{ $t('layout.navigation00') }}
      </VText>
    </NuxtLinkLocale>
    <NuxtLinkLocale
      to="/draft/"
      class="sharedNavigationText"
      data-test-id="bandTopNavigationStartCampaignCTA"
      @click="emitDraftStart"
    >
      <VText :cfg="commonTextConfig">
        {{ $t('layout.navigation.button.startNewDraft.campaign') }}
      </VText>
    </NuxtLinkLocale>
    <NuxtLinkLocale
      to="/band/dashboard/"
      data-test-id="bandTopNavigationCampaignsCTA"
      class="sharedNavigationText"
    >
      <VText :cfg="commonTextConfig">
        {{ $t('layout.navigation04') }}
      </VText>
    </NuxtLinkLocale>
    <NuxtLinkLocale
      v-if="USER_HAS_PAID"
      to="/band/referral/"
      data-test-id="bandTopNavigationReferralCTA"
      @click="trackArtistReferral"
    >
      <i class="fas fa-gift icon lg:!tw-hidden" />
      <span class="sharedNavigationText !tw-hidden lg:!tw-block">
        <VText v-once :cfg="commonTextConfig">
          <span
            class="tw-inline-flex tw-items-center tw-justify-center tw-rounded-sm tw-bg-orange-700 tw-px-2 tw-py-1 tw-text-xs tw-capitalize tw-text-white"
          >
            {{ $t('common.new') }}
          </span>
          <span>{{ $t('layout.navigation13') }}</span>
        </VText>
      </span>
    </NuxtLinkLocale>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import VText from '~/components/ui/VText.vue'

import EventsMixin from '~/mixins/events'
import SegmentCampaignEventsMixin from '~/mixins/Segment/Campaign'
import SegmentMixin from '~/mixins/Segment/Segment'

import { useUserStore } from '~/stores/user'

import type { PropConfigType } from '~/components/ui/VText.vue'

export default defineComponent({
  components: {
    VText,
  },
  mixins: [EventsMixin, SegmentMixin, SegmentCampaignEventsMixin],
  data() {
    const commonTextConfig: PropConfigType = 'sans/14/medium'

    return {
      displayDashMenu: false,
      commonTextConfig,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      GROOVIZ: 'grooviz',
      USER_HAS_PAID: 'HAS_PAID',
      FIRST_PAYMENT_DATE: 'first_payment_date',
    }),
    chevronFeedback(): 'up' | 'down' {
      return this.displayDashMenu ? 'up' : 'down'
    },
  },
  methods: {
    trackArtistReferral(): void {
      this.trackSegmentEvent('Referral Artist - Page Accessed', {
        accessed_from: 'top navbar',
      })
    },
    emitDraftStart(): void {
      this.trackCampaignInitiated('header')
      this.trackEvent({
        category: 'Campaign',
        action: 'Start',
        method: 'Navigation',
        balance: this.GROOVIZ,
        firstPaymentDate: this.FIRST_PAYMENT_DATE,
      })
    },
    toggleDashMenu(): void {
      this.displayDashMenu = !this.displayDashMenu
    },
    closeDashMenu(): void {
      this.displayDashMenu = false
    },
  },
})
</script>

<style lang="scss" scoped>
.dashMenu {
  top: calc(100% + 20px);
}

.icon {
  @apply tw-mx-3 tw-h-4;

  @screen lg {
    @apply tw-mx-4;
  }

  @screen xl {
    @apply tw-mx-6;
  }
}

.fas.fa-gift {
  @apply tw-cursor-pointer tw-text-base tw-text-orange-500;

  @keyframes wiggleWaggle {
    from {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(15deg);
    }
    75% {
      transform: rotate(-15deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    animation: 100ms ease-in-out 4 1500ms reverse both running wiggleWaggle;
  }
}
</style>
